import axios from 'axios';
import { ActionContext } from 'vuex';
import api from '~/api/client';
import { GlobalConfig } from '@/types/interfaces';

type Context = ActionContext<GlobalConfig, never>;

export async function updateConfig(context: Context): Promise<GlobalConfig> {
  const url = `${window.location.origin}/config.json`;
  const { data }: { data: GlobalConfig } = await axios.get(url, {
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
  context.commit('storeConfig', data);

  const apiData = context.getters.getAxiosConfig;
  api.updateConfig(apiData);

  return data;
}
