import { GlobalConfig } from '@/types/interfaces';
import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  state: {} as GlobalConfig,
  actions,
  getters,
  mutations,
};
