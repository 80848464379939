import { createStore } from 'vuex';
import Config from '@/store/config';

const modules = {
  Config,
};

const store = createStore({
  modules,
});

export default store;
