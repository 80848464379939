import { GlobalConfig } from '@/types/interfaces';

export function isConfigLoaded(state: GlobalConfig): boolean {
  return !!Object.keys(state).length;
}

export function getAxiosConfig(state: GlobalConfig) {
  return {
    BASE_URL: state.BASE_URL,
  };
}
