import { App, Plugin } from 'vue';
import { StytchB2BUIClient } from '@stytch/vanilla-js/b2b';

// Define the plugin
const StytchClient: Plugin = {
  install(app: App, options: { token: string }) {
    const stytchInstance = new StytchB2BUIClient(options.token);

    /* eslint-disable no-param-reassign */
    app.config.globalProperties.$stytch = stytchInstance;
  }
};

export default StytchClient;
