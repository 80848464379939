import { createRouter, createWebHistory } from 'vue-router';
import { RouteNames } from '@/router/routes';

const routes = [
  {
    path: '/',
    name: RouteNames.LOGIN,
    component: () => {
      if (window.location.hostname === 'sponsors.momnt.com') {
        return import(/* webpackChunkName: "ProdLoginView" */ '@/views/ComingSoon.vue');
      }
      return import(/* webpackChunkName: "DevLoginView" */ '@/views/LoginView.vue');
    },
  }, {
    path: '/profile',
    name: RouteNames.PROFILE,
    component: () => import(/* webpackChunkName: "ProfileView" */ '@/views/ProfileView.vue'),
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
