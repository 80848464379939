
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    currentYear(): number {
      return new Date().getFullYear();
    },
  },
});
