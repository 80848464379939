import { createApp } from 'vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import StytchClient from '@/plugins/stytch';
import App from './App.vue';

(async () => {
  // Config needs to load before we can send out any other API requests.
  const config = await store.dispatch('Config/updateConfig');
  const app = createApp(App);
  app.use(router);
  app.use(store);
  app.use(vuetify);
  app.use(StytchClient, { token: config.STYTCH_PUBLIC_TOKEN });

  // Mount the app
  app.mount('#app');
})();
